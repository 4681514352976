<template>
  <div class="family-tree-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <div class="backGroundImg-area">
        <img :src="gameInfo.bgImg" alt="" />
      </div>
      <div class="title-area" :class="{'large':titleLength == 'long'}" v-if="!isSpeakingPage || showTitle">
        <div class="title">
          <div class="title-pinyin pinyin" :class="[titleLength == 'long' ? 'font-pinyin-small': 'font-pinyin-medium']">
            {{ gameInfo.titlePinyin }}
          </div>
          <div class="title-hanzi " :class="[titleLength == 'long' ? 'font-hanzi-small': 'font-hanzi-medium']">
            {{ gameInfo.titleHanzi }}
          </div>
        </div>
      </div>
      <div class="drag-area" v-if="!isSpeakingPage">
        <div class="tree-avatar-area">
          <div class="tree-arae" :class="'lesson-' + lessonNum">
            <div class="people-item">
              <div class="avatar">
                <img
                  :src="gameInfo.selfImg"
                  alt=""
                  class="avatar-img self-img"
                />
                <div class="name">
                  <div class="pinyin pinyin-name">
                    {{ gameInfo.selfPinyin }}
                  </div>
                  <div class="hanzi-name">{{ gameInfo.selfHanzi }}</div>
                </div>
              </div>
            </div>
            <div
              class="people-item"
              v-for="(item, index) in optionsList"
              :key="index"
              :class="'person-' + (index + 1)"
              @drop.stop="dropToAvatar($event, item.id)"
              @dragover.stop="allowDrop($event)"
            >
              <div class="avatar">
                <div class="border">
                  <img
                    :src="item.bgImg"
                    alt=""
                    class="avatar-img"
                    :class="{ dispear: dispearIdList.indexOf(item.id) === -1 }"
                  />
                </div>
                <div class="name">
                  <div class="pinyin pinyin-name">{{ item.pinyin }}</div>
                  <div class="hanzi-name">{{ item.hanzi }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="avatar-area">
            <div
              class="avatar-item"
              v-for="(item, index) in sentenceList"
              :key="index"
              :class="'avatar-' + index"
              @dragstart.stop="dragAvatar($event, item.id)"
              @drop.stop="Sdrop($event)"
              @dragend.stop="dragend()"
              draggable="true"
            >
              <img
                :src="item.bgImg"
                alt=""
                :class="{ notShow: dragId === item.id }"
                v-show="dispearIdList.indexOf(item.id) === -1"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-words-area">
        <div class="words-box">
          <div
            class="hanzi-item"
            v-for="(item, index) in sentenceList"
            :key="index"
            v-show="showHnaziId === item.id"
          >
            <div class="pinyin sentence-hanzi font-pinyin-medium">
              {{ item.pinyin }}
            </div>
            <div class="sentence-hanzi font-hanzi-medium">{{ item.hanzi }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "FamilyTreeGame",
  props: {
    gameInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
    optionsList: {
      type: Array,
      require: true,
      default: () => [],
    },
    sentenceList: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonNum: {
      type: Number,
      require: false,
    },
    isSpeakingPage: {
      type: Boolean,
      require: false,
      default: false,
    },
    showTitle: {
      type: Boolean,
      require: false,
      default: false,
    },
    titleLength: {
      type: String,
      require: false,
    },
  },
  components: {
    PageButton,
  },
  watch: {
    dropAvatarSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004801,
          data: { value },
          text: "FamilyTreeGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragAvatarSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004802,
          data: { value },
          text: "FamilyTreeGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropAvatarEvent", ({ id }) => {
      this.dropToAvatar("socket", id, true);
    });
    this.$bus.$on("dragAvatarEvent", ({ id }) => {
      this.dragAvatar("socket", id, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropAvatarEvent");
    this.$bus.$off("dragAvatarEvent");
  },
  data() {
    return {
      clickIndex: 0,
      isLastStep: false,
      clickSocketInfo: {},
      dispearIdList: [],
      dragId: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: !this.isSpeakingPage ? "look" : "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      step: 0,
      showHnaziId: 0,
      dropAvatarSocketInfo: {},
      dragAvatarSocketInfo: {},
    };
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    dragAvatar(event, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragAvatarSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      console.log(id);

      this.dragId = id;
      // this.dragId = id;
      if (this.dragId === id) {
        console.log("拖拽中");
      }
    },
    Sdrop(event) {
      event.preventDefault();
    },
    
    dragend() {
        this.dragId=null
    },
    dropToAvatar(event, id, isFromSocket = false) {
      console.log("拖拽成功");

      if (!isFromSocket) {
        this.dropAvatarSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      console.log(id);
      if (id === this.dragId) {
        this.dispearIdList.push(id);
        playCorrectSound(true, false);
        this.showHnaziId = id;
        this.step++;
        if (this.step === this.optionsList.length) {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound();
        }
      } else {
        playCorrectSound(false);
      }
    },
    allowDrop(event) {
      event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.family-tree-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .backGroundImg-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-area,
    .drag-area,
    .bottom-words-area {
      position: absolute;
      top: 0;
      //   left: 0;
    }
    .title-area {
      top: 10%;
      right: 0;
      left: none;
      width: 18%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      color: #fff;

      &.large {
        width: 29%;
        height: 13%;

        top:7%;
      }
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .drag-area {
      z-index: 10;
      width: 100%;
      height: 100%;

      .tree-avatar-area {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .tree-arae {
          flex: 2;
          position: relative;
          width: 100%;
          height: 100%;
          .people-item {
            position: absolute;
            top: 4%;
            left: 21%;
            .avatar {
              display: flex;
              justify-content: center;
              // align-items: center;
              position: relative;
              background: #fff;
              border-radius: 50%;
              .border {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                border: 10px solid #224e96;
              }
              .avatar-img {
                border-radius: 50%;
                // border: 10px solid #224e96;
                width: 100%;
                max-width: 80px;
              }
              .self-img {
                border: 10px solid #224e96;
              }
              .dispear {
                opacity: 0;
              }
              .name {
                width: 30%;
                background: #224e96;
                color: #fff;
                position: absolute;
                bottom: -48%;
                padding: 5px 10px;
                border-radius: 15px;
                width: 85%;
                .pinyin-name,
                .hanzi-name {
                  text-align: center;
                }
              }
            }
          }
          .person-1 {
            top: 2%;
            left: 56%;
          }
          .person-2 {
            top: 38%;
            left: 21%;
          }
          .person-3 {
            top: 30%;
            left: 56%;
          }
        }
        .lesson-13 {
          .people-item {
            top: 2%;
            left: 43%;
          }
          .person-1 {
            top: 15%;
            left: 23%;
          }
          .person-2 {
            top: 14%;
            left: 62%;
          }
          .person-3 {
            top: 44%;
            left: 21%;
          }
          .person-4 {
            top: 41%;
            left: 63%;
          }
        }
        .avatar-area {
          flex: 1;
          position: relative;
          height: 100%;
          .avatar-item {
            position: absolute;
            cursor: pointer;
            img {
              width: 85%;
              -webkit-user-drag: inherit;
            }
            .notShow {
              opacity: 0;
            }
          }
          .avatar-0 {
            top: 31%;
            left: 35%;
          }
          .avatar-1 {
            top: 47%;
            left: -3%;
          }
          .avatar-2 {
            top: 18%;
            left: -2%;
          }
          .avatar-3 {
            top: 54%;
            left: 42%;
          }
        }
      }
    }
    .bottom-words-area {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .hanzi-item {
        width: 50%;
        margin: 0 auto;
        background: #cd4c3f;
        position: absolute;
        bottom: 7%;
        left: 25%;
        padding: 1% 1%;
        color: #fff;
        border-radius: 40px;
        .sentence-hanzi,
        .sentence-pinyin {
          text-align: center;
        }
      }
    }
  }
}
</style>